export enum Role {
    None = 0,
    Player = 1,
    Tester = 2,
    Bot = 3,
    Admin = 4,
    EventCreator = 5,
    Editor = 6,
    BotOffline = 7,
    SalesMember = 8,
    SalesManager = 9,
}

export enum RealmRole {
    Admin = "Admin",
    SalesMember = "sales-member",
    SalesManager = "sales-manager",
}